
import { defineComponent, onMounted, onUpdated, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import { Document } from '@/models/document.model';
import { lockClosed, lockOpen, home } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import * as eventBus from '@/services/event.bus.js';
import { useRouter, useRoute } from 'vue-router';
import JnPreviewDoc from './PreviewDoc.vue';
import { useCookie } from '@vue-composable/cookie';
addIcons({
  'lock-closed': lockClosed,
  'lock-open': lockOpen,
  home,
});

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonItem,
  IonAvatar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonHeader,
  IonToast,
  modalController,
} from '@ionic/vue';

export default defineComponent({
  name: 'JnDoc',
  props: {
    showAbstract: {
      type: Boolean,
      required: true,
    },
    doc: {
      type: Document,
      required: true,
    },
  },
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    // IonCardTitle,
    IonCardSubtitle,
    IonIcon,
    // IonItem,
    //  IonAvatar,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonToast,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const paywall = ref(true);

    const alertMessage = ref('');
    const showAlert = ref(false);
    const setAlertOpen = (state: boolean) => (showAlert.value = state);

    // onMounted(async () => {});
    onMounted(async () => {
      eventBus.default.$on('paywall', function(data: boolean) {
        paywall.value = data;
      });

      // Eventuale ulteriore logica per capire se ha superato il paywall va messa qui
      const paywallCookie = useCookie('paywall');
      if (!paywallCookie.cookie.value) {
        paywall.value = true;
      } else {
        paywall.value = paywallCookie.cookie.value == 'true';
      }
    });

    const viewDocument = async () => {
      // if (props.doc.access == 1 || !paywall.value) {
      if (props.doc.isJuranews) {
        router.push('/docs/juranews/' + props.doc.id);
      } else {
        router.push('/docs/juranet/' + props.doc.id);
      }
    };

    return { viewDocument, paywall, alertMessage, showAlert, setAlertOpen };
  },
});
