
import { defineComponent, onMounted, ref } from "vue";
import { mapState, useStore } from "vuex";
import { Picklist } from "../../models/picklist.model";
import { document, close } from "ionicons/icons";
import { addIcons } from "ionicons";
import { JnService } from "../../services/jn.service";
import { EsService } from "../../services/es.service";

import {
  SearchTypes,
  QueryTypes,
  OrderingTypes,
  Categories,
} from "../../models/enums.model";

addIcons({
  document: document,
  close: close,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonItemDivider,
  IonIcon,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonButton,
  modalController,
} from "@ionic/vue";
import { SearchData } from "@/models/search.model";

export default defineComponent({
  name: "JnNewsSearchParams",
  components: {
    IonContent,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    // IonItemDivider,
    IonIcon,
    IonInput,
    IonToolbar,
    IonHeader,
    IonPage,
    IonTitle,
    IonSelect,
    IonSelectOption,
    IonButton,
  },

  setup(props) {
    const store = useStore();

    const optArea = ref(SearchTypes.ALL);
    const optText = ref("");
    const optType = ref(QueryTypes.OR);
    const optTags = ref([] as Array<string>);
    let optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;

    // const optTipoOrdinamento = ref<OrderingTypes>();
    let optNumRisultati = "20";

    const tipiQuery = ref([] as Array<Picklist>);
    const aree = ref([] as Array<Picklist>);
    const tipiOrdinamento = ref([] as Array<Picklist>);
    const numRisultati = ref([] as Array<Picklist>);
    const tags = ref([] as Array<Picklist>);

    const searchData = ref(
      new SearchData({
        index: process.env.VUE_APP_JURANEWS_INDEXES,
        from: 0,
        size: 10,
        orderingType: OrderingTypes.DATA_CREAZIONE,
        idCategory: [Categories.ULTIME_NEWS, Categories.APPROFONDIMENTI],
        searchInMassime: false,
      })
    );

    onMounted(async () => {
      tipiQuery.value = JnService.getQueryTypes();
      aree.value = JnService.getTipiRicercaGlobale();
      tipiOrdinamento.value = JnService.getTipiOrdinamento();
      numRisultati.value = JnService.getNumeroRisultati();

      const tagsRaw = JSON.parse(await EsService.getOptions("tags"));

      for (const tag of tagsRaw) {
        if (tag.value != "ROOT") {
          tags.value.push(new Picklist({ value: tag.id, label: tag.value }));
        }
      }
    });

    const search = async () => {
      searchData.value.keywords = optText.value;
      searchData.value.queryType = optType.value;
      if (optTipoOrdinamento) {
        searchData.value.orderingType = optTipoOrdinamento;
      }
      if (optNumRisultati) {
        searchData.value.size = Number(optNumRisultati);
      }

      /* if (optArea.value == GlobalSearchTypes.NEWS) {
        searchData.index = process.env.VUE_APP_JURANEWS_INDEXES;
        searchData.idCategory = [Categories.NEWS];
      } else if (this.optArea == GlobalSearchTypes.AREE_TEMATICHE) {
        this.searchData.index = process.env.VUE_APP_JURANEWS_INDEXES;
        this.searchData.idCategory = [
          Categories.COMMENTI,
          Categories.SCHEDA_AUTORALE,
          Categories.CONCORSI,
          Categories.SINTESI_GIURISPRUDENZIALI,
        ];
      } else if (this.optArea == GlobalSearchTypes.BANCA_DATI) {
        this.searchData.idCategory = undefined;
        this.searchData.index = process.env.VUE_APP_JURANET_INDEXES;
      } else {
        this.searchData.idCategory = undefined;
        this.searchData.index = process.env.VUE_APP_ALL_INDEXES;
      }

      const docs = await EsService.getDocs(this.searchData);
      if (docs.length == 0) {
        this.alert = true;
        this.alertMessage = 'Nessun risultato trovato';
      }*/
      modalController.dismiss(searchData);
    };

    const close = async () => {
      modalController.dismiss(undefined);
    };

    const clear = async () => {
      optText.value = "";
      optType.value = QueryTypes.OR;
      optArea.value = SearchTypes.ALL;
      optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;
      optNumRisultati = "20";
    };

    return {
      tipiQuery,
      aree,
      optArea,
      optText,
      optType,
      optTags,
      optTipoOrdinamento,
      optNumRisultati,
      tipiOrdinamento,
      numRisultati,
      tags,
      search,
      close,
    };
  },
});
