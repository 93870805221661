<template>
  <ion-card @click="viewDocument()">
    <ion-card-header color="tertiary">
      <ion-grid>
        <ion-row>
          <ion-col class="ion-text-start">
            <ion-text color="secondary" v-if="doc.materia">
              <b>{{ doc.materia }}</b>
            </ion-text>
            <ion-text color="secondary" v-if="!doc.materia">
              <b>{{ doc.categories[0].toUpperCase().replaceAll('-', ' ') }}</b>
            </ion-text>
          </ion-col>
          <ion-col class="ion-text-end">
            <ion-text color="secondary">
              <b>{{ doc.dateFormatted }}</b>
            </ion-text>
          </ion-col>
          <ion-col size="1" class="ion-text-end">
            <ion-icon
              :class="
                doc.access == 1 || (doc.access != 1 && !paywall)
                  ? 'unlocked hydrated'
                  : 'locked hydrated'
              "
              :name="
                doc.access == 1 || (doc.access != 1 && !paywall)
                  ? 'lock-open'
                  : 'lock-closed'
              "
            ></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row size="12">
          <ion-col size="12">
            <ion-text>
              <h6>
                <b>{{ doc.title }}</b>
              </h6>
            </ion-text>
            <ion-card-subtitle class="abstract">
              <i><div class="abstract" v-html="doc.abstract"/></i>
            </ion-card-subtitle>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-header>
    <ion-card-content class="text">
      <ion-toast
        :is-open="showAlert"
        :message="alertMessage"
        :duration="2000"
        @didDismiss="setAlertOpen(false)"
        color="secondary"
      >
      </ion-toast>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <div
              class="text"
              v-html="showAbstract ? doc.abstract : doc.shortText1"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUpdated, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import { Document } from '@/models/document.model';
import { lockClosed, lockOpen, home } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import * as eventBus from '@/services/event.bus.js';
import { useRouter, useRoute } from 'vue-router';
import JnPreviewDoc from './PreviewDoc.vue';
import { useCookie } from '@vue-composable/cookie';
addIcons({
  'lock-closed': lockClosed,
  'lock-open': lockOpen,
  home,
});

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonItem,
  IonAvatar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonHeader,
  IonToast,
  modalController,
} from '@ionic/vue';

export default defineComponent({
  name: 'JnDoc',
  props: {
    showAbstract: {
      type: Boolean,
      required: true,
    },
    doc: {
      type: Document,
      required: true,
    },
  },
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    // IonCardTitle,
    IonCardSubtitle,
    IonIcon,
    // IonItem,
    //  IonAvatar,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonToast,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const paywall = ref(true);

    const alertMessage = ref('');
    const showAlert = ref(false);
    const setAlertOpen = (state: boolean) => (showAlert.value = state);

    // onMounted(async () => {});
    onMounted(async () => {
      eventBus.default.$on('paywall', function(data: boolean) {
        paywall.value = data;
      });

      // Eventuale ulteriore logica per capire se ha superato il paywall va messa qui
      const paywallCookie = useCookie('paywall');
      if (!paywallCookie.cookie.value) {
        paywall.value = true;
      } else {
        paywall.value = paywallCookie.cookie.value == 'true';
      }
    });

    const viewDocument = async () => {
      // if (props.doc.access == 1 || !paywall.value) {
      if (props.doc.isJuranews) {
        router.push('/docs/juranews/' + props.doc.id);
      } else {
        router.push('/docs/juranet/' + props.doc.id);
      }
    };

    return { viewDocument, paywall, alertMessage, showAlert, setAlertOpen };
  },
});
</script>
